$div-width: 450px;

.pageReloadNotification {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 5px;
  left: calc(50% - #{$div-width / 2});
  z-index: 8000;
  padding-left: 8px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 8px;

  width: $div-width;
  min-height: 68px;
  max-height: 400px;

  box-sizing: border-box;

  // border: 1px solid $lineDark;
  border: 2px solid $lineBlue;
  background: rgb(239, 244, 255);
  border-radius: 3px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);

  cursor: default;
  user-select: none;

  justify-content: center;
}

.pageReloadNotification-message {
  color: $textDark;
  font-size: 15px;
  font-weight: $lato-bold;
  overflow-wrap: break-word;

 text-overflow: clip;
  white-space: normal;
  overflow: hidden;
  text-align: center;
  margin-top: 4px;
}

.pageReloadNotification span.pageReloadNotification-actionWord {
  margin-left: auto;
  margin-right: auto;
  font-weight: $lato-heavy;
  color: $textBlack;
}

.pageReloadNotification-buttonContainer {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

button.pageReloadNotification-button {
  margin-left: 0px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;

  font-size: 14.5px;
  font-weight: $lato-bold;

  color: $textBlue;
  border-radius: 4px;

  border: none;
  box-shadow: none;

  cursor: pointer;
  background: none;
  background: $blue-extraLight;

  text-align: left;
}

button.pageReloadNotification-button-dismiss {
  background: transparentize($red, 0.95);
  color: darken($red, 4%);
}

button.pageReloadNotification-button-dismiss:hover {
  background: darken($blue-extraLight, 1%);
  color: darken($textBlue, 7%);

  background: transparentize($red, 0.9);
  color: darken($red, 10%);
}
button.pageReloadNotification-button-reload {
  margin-left: 25px;
  background: transparentize($green, 0.95);
  color: darken($green-dark, 4%);
}

button.pageReloadNotification-button-reload:hover {
  background: darken($blue-extraLight, 1%);
  color: darken($textBlue, 7%);

  background: transparentize($green, 0.9);
  color: darken($green-dark, 10%);
}
