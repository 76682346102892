$outsideLeftPadding-editMediaBar: 14px;
$insideLeftPadding-editMediaBar: 36px;

.editor-canvas-editMediaBar{

	width: 174px;
	flex-grow: 0;
	flex-shrink: 0;

	border-radius: 6px;

	background: white;
	border: 1px solid rgb(208,209,214);

	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);

	margin-left: 10px;
	margin-top: 38px;

	padding-top: 15px;
	padding-bottom: 6px;

	position: relative;

	opacity: 1;
	transform: scale(1);
	transition: all 200ms cubic-bezier(.4,.17,.5,1.05);
}

.editor-canvas-editMediaBar--disabled{
	pointer-events: none;
	cursor: default;
	opacity: 0.6;
	transform: scale(0.95);
	transition: all 150ms cubic-bezier(.4,.17,.5,1.03);
}

img.editor-canvas-editMediaBar-imageToCopy{
	height: 0px;
	width: 0px;
	opacity: 0;
}

.editor-canvas-editMediaBar-header{
	font-size: 15px;
	font-weight: $lato-heavy;
	color: $textBlack;
	margin-left: $outsideLeftPadding-editMediaBar;
	padding-bottom: 9px;
	letter-spacing: 0.01em;

	// background: $tempb;
	cursor: default;
	user-select: none;
}

// .editor-canvas-editMediaBar--disabled
// .editor-canvas-editMediaBar-header{
// 	opacity: 0.6;
// }

button.editor-canvas-editMediaBar-btn{
	display: flex;
	align-items: center;
	width: 100%;
	height: 36px;

	position: relative;

	border: none;
	box-shadow: none;

	background: $white;

	cursor: pointer;
	padding: 0px;

	opacity: 0.8;

	user-select: none;
}

button.editor-canvas-editMediaBar-btn:disabled {
  // opacity: 0.4;
  // pointer-events: none;
  cursor: not-allowed;
}

.editor-canvas-editMediaBar--itemIsLockedByAnotherUser
button.editor-canvas-editMediaBar-btn:disabled{
	opacity: 0.4;
}


.editor-canvas-editMediaBar-btn-iconContainer{
	width: $insideLeftPadding-editMediaBar;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-canvas-editMediaBar-btn-label{
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;
	text-align: left;

	color: darken($textMid, 10%);
}

.editor-canvas-editMediaBar-btn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: lighten($textMid, 2%);
	margin-left: 1px;
}

.editor-canvas-editMediaBar-btn-iconContainer svg.editorIcon-editMediaTrim{
	width: 20px;
	height: 20px;
}

.editor-canvas-editMediaBar-btn-iconContainer svg.editorIcon-replaceMedia{
	height: 16px;
	width: 16px;
}

.editor-canvas-editMediaBar-btn-iconContainer svg.editorIcon-copyMedia{
	width: 15px;
	height: 15px;
}

.editor-canvas-editMediaBar-btn-iconContainer svg.editorIcon-openLink{
	height: 12px;
	width: 12px;
}

.editor-canvas-editMediaBar-btn-bottomBorder{
	position: absolute;
	left: $insideLeftPadding-editMediaBar;
	bottom: 0px;
	right: 0px;
	height: 1px;
	background: darken($lineMid, 5%);
}

.editor-canvas-editMediaBar-btn-topHoverBorder,
.editor-canvas-editMediaBar-btn-bottomHoverBorder{
	background: $blue;
	background: #E7F1FD;
	position: absolute;
	left: 0px;
	right: 0px;
	height: 1px;

	visibility: hidden;
}

.editor-canvas-editMediaBar-btn-topHoverBorder{
	top: -1px;
}

.editor-canvas-editMediaBar-btn-bottomHoverBorder{
	bottom: 0px;
}

.editor-canvas-editMediaBar--viewOnly
.editor-canvas-editMediaBar-btn-bottomBorder{
	display: none;
}

//

button.editor-canvas-editMediaBar-btn:hover{
	background: $blue-extraLight;
	opacity: 1;
	position: relative;
	z-index: 10;
}


button.editor-canvas-editMediaBar-btn:hover
.editor-canvas-editMediaBar-btn-label{
	color: darken($blue, 7%);
}

button.editor-canvas-editMediaBar-btn:hover
.editor-canvas-editMediaBar-btn-iconContainer svg{
	fill: $blue;
	opacity: 1;
	transform: scale(1.02);
}

button.editor-canvas-editMediaBar-btn:hover
.editor-canvas-editMediaBar-btn-bottomBorder{
	display: none;
}

button.editor-canvas-editMediaBar-btn:nth-last-child(2)
.editor-canvas-editMediaBar-btn-bottomBorder{
	display: none;
}

.editor-canvas-editMediaBar a:nth-last-child(2)
button.editor-canvas-editMediaBar-btn
.editor-canvas-editMediaBar-btn-bottomBorder{
	display: none;
}

.editor-canvas-editMediaBar a:nth-child(5)
button.editor-canvas-editMediaBar-btn
.editor-canvas-editMediaBar-btn-bottomBorder{
	display: none;
}


button.editor-canvas-editMediaBar-btn:hover
.editor-canvas-editMediaBar-btn-topHoverBorder,
button.editor-canvas-editMediaBar-btn:hover
.editor-canvas-editMediaBar-btn-bottomHoverBorder{
	visibility: visible;
}


button.editor-canvas-editMediaBar-btn:active{
	background: darken($blue-extraLight, 2%);
}

button.editor-canvas-editMediaBar-btn:active
.editor-canvas-editMediaBar-btn-label{
	color: darken($blue, 9%);
}

button.editor-canvas-editMediaBar-btn:active
.editor-canvas-editMediaBar-btn-iconContainer svg{
	fill: darken($blue, 2%);
	opacity: 1;
	transform: scale(1.03);
}


//


button.editor-canvas-editMediaBar-removeBtn{
	
	height: 34px;
	padding: 0px;
	width: calc(100% - 10px);
	margin-left: 5px;
	margin-top: 8px;

	font-size: 14px;
	font-weight: $lato-bold;
	color: $textLight;
	background: none;
	border-radius: 5px;
	letter-spacing: 0.01em;


	border: none;

	cursor: pointer;

	box-shadow: none;
	user-select: none;
}

button.editor-canvas-editMediaBar-removeBtn:hover{
	background: transparentize($red, 0.9);
	color: darken($red, 5%);
}

button.editor-canvas-editMediaBar-removeBtn:disabled{
	// opacity: 0.4;
	pointer-events: none;
}

.editor-canvas-editMediaBar--itemIsLockedByAnotherUser
button.editor-canvas-editMediaBar-removeBtn:disabled{
	opacity: 0.4;
}

// LOADING SPLACEHOLDER

.editor-canvas-editMediaBar--loadingSpinnerContainer{
	position: absolute;
	top: 12px;
	right: 12px;
	height: 22px;
	width: 22px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;
}


.editor-canvas-editMediaBar--loadingSpinner{

	height: 16px;
	width: 16px;
  
  border-radius: 50%;

  border: 3px rgba(0,0,0,0.1) solid;
  border-top: 3px rgba(0,0,0,0.4) solid;

  opacity: 0.75;

  animation: spinnerRotateAnimation 0.6s infinite linear;
}
